<template>
  <div class="diaryEditor">
    <section v-show="!loadingPage">
      <BaseMenuBar
        :nameList="[studyDetails.name, diaryDetails.name]"
        :idList="[studyId]"
      ></BaseMenuBar>

      <BaseTabs
        :tabDetails="tabDetails"
        :selectedView="selectedView"
        @show-view="showView"
      ></BaseTabs>

      <section v-show="selectedView === 0">
        <button type="button" id="buttonExportDiary" @click="exportDiary">
          {{ 'interventionTranslation.export' | translate }}
        </button>
      </section>

      <BaseSelectLanguage
        v-if="selectedView != 2 && diaryDetails.locales"
        :localesArray="diaryDetails.locales"
        :selectedLanguage2="selectedLanguage2"
        :isTranslationMode="isTranslationMode"
        @close-notification="closeNotification"
        @change-language2="changeLanguage2"
        @change-translationmode="changeTranslationMode"
      ></BaseSelectLanguage>

      <section v-show="selectedView === 0" :id="diaryDetailsFormId" class="wrapper">
        <section v-show="!loadingLanguage">
          <!-- normal language (left) -->
          <section :class="{col: isTranslationMode, sec50:isTranslationMode}">
            <InterventionEditorDetails
              :interventionDetails="diaryDetails"
              :translation="currentTranslation"
              :isTranslation="false"
              :isTranslationMode="isTranslationMode"
              :selectedLanguageForTranslation="selectedLanguage2"
              :isDiary="true"
              :studyId="studyId"
              @close-notification="closeNotification"
              @change-attribute="changeAttributeForDetails"
              @change-schedules="changeSchedulesForDetails"
              @show-modal="showModal"
            ></InterventionEditorDetails>
          </section>

          <!-- translation language (right) -->
          <section :class="{col: isTranslationMode, sec50:isTranslationMode}">
            <section v-if="isTranslationMode && getIsEditable">
              <InterventionEditorDetails
                :interventionDetails="diaryDetails"
                :translation="currentTranslation2"
                :isTranslation="true"
                :isTranslationMode="isTranslationMode"
                :selectedLanguageForTranslation="selectedLanguage2"
                :isDiary="true"
                :studyId="studyId"
                @close-notification="closeNotification"
                @change-attribute="changeAttributeForDetails"
                @show-modal="showModal"
              ></InterventionEditorDetails>
            </section>
          </section>

          <section class="wrapper">
            <button type="submit" id="buttonSaveDiaryDetails" :disabled="isSaved || !formChanged" @click="saveDiaryDetails">
              {{ saveDiaryDetailsText }}
            </button>
            <button
              id="structureLockButtonDiary"
              :disabled="!getIsOwner || structureLockLoading"
              class="structureLockButton"
              v-tooltip="disabledStructureLockText"
              @click="structureLockModalVisible = true"
            >
              <span v-if="diaryDetailsComplete.structure_lock">
                <i class="fa fa-lock"></i>
                {{ $t("interventionTranslation.lockedStructure") }}
              </span>
              <span v-else>
                <i class="fa fa-lock-open"></i>
                {{ $t("interventionTranslation.unlockedStructure") }}
              </span>
            </button>
            <i
              class="far fa-question-circle"
              v-tooltip="{ content: structureLockTooltip, theme: 'info-tooltip' }"
            ></i>
          </section>
        </section>

        <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingLanguage"></i>
      </section>

      <section v-if="!loadingElements" id="lessonElements">
        <LessonEditorElements
          v-show="selectedView === 1"
          :editorModalsVisible="warningChangesVisible"
          :lessonDetails="diaryDetailsComplete"
          :interventionDetails="{'study_id': studyId}"
          :formChanged="formChanged"
          :isTranslationMode="isTranslationMode"
          :selectedLanguageForTranslation="selectedLanguage2"
          :isDiary="true"
          :isSkill="false"
          :studyId="studyId"
          :currentPageLessonElements="currentPageLessonElements"
          @close-notification="closeNotification"
          @show-modal="showModal"
          @route-to="routeTo"
        ></LessonEditorElements>
      </section>

      <section v-show="selectedView === 2" id="translationStatus" class="wrapper">
        <div v-if="diaryDetails.translation_status != null" key="progressDiv">
          <section v-for="language in Object.keys(diaryDetails.translation_status)" :key="language">
            <section class="wrapper">
              <section class="col sec3">
                {{language}}
              </section>
              <section class="col sec25">
                <progress :value="diaryDetails.translation_status[language]" max="1"></progress>
              </section>
            </section>
          </section>
        </div>
        <div v-else key="noProgressDiv">
          {{ 'interventionTranslation.noProgress' | translate }}
        </div>
      </section>
    </section>
    <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingPage"></i>

    <BaseModal
      v-if="warningChangesVisible"
      id="changesModal"
      :bodyText="warningText"
      :isLeaveSiteWarning="true"
      :leftButtonText="$t('interventionTranslation.leave')"
      @close-modal="resolvePromise"
    ></BaseModal>

    <BaseModal
      v-if="deleteLanguageModalVisible"
      id="deleteDiaryLanguageModal"
      :headerText="deleteLanguageHeaderText"
      :bodyText="deleteLanguageText"
      :leftButtonText="$t('interventionTranslation.ok')"
      @close-modal="deleteLanguageModal"
    ></BaseModal>

    <BaseModal
      v-if="structureLockModalVisible"
      id="structureLockModal"
      class="structureLockModal"
      :headerText="$t('interventionTranslation.structureLockModalHeader')"
      :leftButtonText="$t('generalTranslation.yes')"
      @close-modal="changeStructureLock"
    >
      <template v-slot:body>
        <div v-if="diaryDetailsComplete.structure_lock">
          <label>{{ $t("interventionTranslation.structureLockModalLocked") }}</label>
          <p>{{ $t("interventionTranslation.structureLockModalLockedInfo") }}</p>
        </div>
        <div v-else>
          <label>{{ $t("interventionTranslation.structureLockModalUnlocked") }}</label>
          <p>{{ $t("interventionTranslation.structureLockModalUnlockedInfo") }}</p>
        </div>
        <p>{{ $t("interventionTranslation.structureLockModalChanges") }}</p>
      </template>
    </BaseModal>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import router from "../router";
import httpHelper from "../mixins/httpHelper";
import utils from "../mixins/utils";
import elementJSONToPropsHelper from "../mixins/elementJSONToPropsHelper";
import BaseMenuBar from "./BaseMenuBar.vue";
import BaseTabs from "./BaseTabs.vue";
import BaseNotification from "./BaseNotification.vue";
import BaseModal from "./BaseModal.vue";
import BaseSelectLanguage from "./BaseSelectLanguage.vue";
import InterventionEditorDetails from "./InterventionEditorDetails.vue";
import LessonEditorElements from "./LessonEditorElements.vue";

export default {
  name: "DiaryEditor",

  components: {
    BaseMenuBar,
    BaseTabs,
    BaseNotification,
    BaseModal,
    BaseSelectLanguage,
    InterventionEditorDetails,
    LessonEditorElements,
  },

  mixins: [httpHelper, elementJSONToPropsHelper, utils],

  props: ["view", "questionnaireId"],

  data: function() {
    return {
      diaryDetails: [],
      diaryDetailsComplete: [],
      selectedLanguage2: "",
      warningChangesVisible: false,
      nextRouteName: "",
      isSaved: false,
      selectedView: 1,
      loadingLanguage: true,
      formChanged: false, //true if diary details changed by user & not yet saved
      isTranslationMode: false,
      currentTranslation: "",
      currentTranslation2: "",
      otherModalVisible: false,
      studyDetails: [],
      selectMediaVisible: false,
      loadingPage: true,
      loadingElements: true,
      resolvePromise: null,
      rejectPromise: null,
      currentPageLessonElements: 1,
      copyElementsBool: false,
      diaryDetailsFormId: "diaryDetailsForm",
      deleteLanguage: "",
      deleteLanguageModalVisible: false,
      structureLockLoading: false,
      structureLockModalVisible: false,
    };
  },

  computed: {
    ...mapGetters([
      "getSelectedLanguage",
      "getNotificationText",
      "getPageRefreshBool",
      "getPageRefreshDetails",
      "getMyRoleForStudy",
      "getFormElementsChanged",
      "getUserId",
      "getConditionEditor",
      "getIsEditable",
      "getAllDetailsLocalesChanged",
      "getChangedDetailsLocales",
      "getIsOwner",
    ]),

    changedLocales: function() {
      const newChangedLocales = [];
      for (const l in this.diaryDetailsComplete.locales) {
        const locale = this.diaryDetailsComplete.locales[l];
        if (this.getChangedDetailsLocales.includes(locale)) {
          newChangedLocales.push(locale);
        }
      }
      return newChangedLocales;
    },

    disabledStructureLockText: function() {
      return !this.getIsOwner ?
        Vue.i18n.translate("interventionTranslation.structureLockButtonDisabled") :
        "";
    },

    structureLockTooltip: function() {
      return this.diaryDetailsComplete.structure_lock ?
        Vue.i18n.translate("interventionTranslation.structureLockModalLockedInfo") :
        Vue.i18n.translate("interventionTranslation.structureLockModalUnlockedInfo");
    },

    saveDiaryDetailsText() {
      if (this.changedLocales.length > 0) {
        const languages = `(${this.changedLocales.join(", ")})`;
        return Vue.i18n.translate("generalTranslation.saveLocales", { locales: languages });
      } else {
        return Vue.i18n.translate("generalTranslation.save");
      }
    },

    studyId: function() {
      return Number(this.diaryDetails.study_id);
    },

    diaryId: function() {
      return Number(this.$route.params.diaryId);
    },

    deleteLanguageText: function() {
      return Vue.i18n.translate("lessonTranslation.deleteLanguageConfirm", { language: this.deleteLanguage });
    },

    deleteLanguageHeaderText: function() {
      return Vue.i18n.translate("lessonTranslation.deleteLanguageConfirmHeader");
    },

    warningText: function() {
      let string = Vue.i18n.translate("generalTranslation.unsavedChanges") + ": ";
      if (this.formChanged) {
        string += Vue.i18n.translate("diaryTranslation.diaryDetails");
      }
      if (this.getFormElementsChanged && this.nextRouteName != "conditionEditor") {
        string += (this.formChanged ? ", " : "") + Vue.i18n.translate("diaryTranslation.diaryElements");
      }
      return string;
    },

    tabDetails: function() {
      return [
        { text: Vue.i18n.translate("diaryTranslation.diaryDetails"), show: true, id: "details" },
        { text: Vue.i18n.translate("diaryTranslation.diaryElements"), show: true, id: "elements" },
        { text: Vue.i18n.translate("interventionTranslation.translationProgress"), show: true, id: "progress" },
      ];
    }
  },

  watch:{
    '$route.params.diaryId': function(){
      if(this.copyElementsBool){
        //get new data after copy elements in different lesson
        this.copyElementsBool = false;
        this.loadingPage = true;
        this.loadingLanguage = true;
        this.loadingElements = false;
        this.allElementsChanged();
        this.initDiary(true, false);
      }else{
        //get new data when going back from lesson where elements were copied to to lesson where elements where copied from
        this.loadingPage = true;
        this.loadingLanguage = true;
        this.loadingElements = true;
        this.initDiary(true, true);
      }
    },

    formChanged(newVal) {
      if (!newVal) {
        this.clearChangedDetailsLocales();
      }
    },

    getNotificationText(newVal) {
      if (newVal !== "" && newVal.type === "error") {
        this.isSaved = false;
        this.loadingLanguage = false;
      } else if (newVal !== "" && newVal.type === "success") {
        if (newVal.text === Vue.i18n.translate("diaryTranslation.updateDiaryDetailsSuccess")) {
          this.formChanged = false;
          this.loadingLanguage = true;
          this.initDiary(false, true);
        }
        this.isSaved = false;
      }
    },

    getSelectedLanguage(){
      this.setCurrentTranslations(false);
    },

    selectedLanguage2(newVal){
      if (newVal != "") {
        this.setCurrentTranslations(true);
      } else if (this.currentTranslation2 != "") {
        this.addTranslation(this.diaryDetails.translations, this.currentTranslation2);
        this.currentTranslation2 = "";
      }
    },
  },

  mounted(){
    if(this.getNotificationText.text != Vue.i18n.translate('diaryTranslation.createDiarySuccess')){
      this.closeNotification();
    }
    this.SET_PREVIEWANSWERS({});
    if((this.getPageRefreshBool || this.getConditionEditor) && this.getPageRefreshDetails.id === this.diaryId){
      this.SET_CONDITIONEDITOR(false);
      this.diaryDetails = this.getPageRefreshDetails.diaryDetails;
      this.studyDetails = this.getPageRefreshDetails.studyDetails;
      this.formChanged = this.getPageRefreshDetails.formChanged;
      this.loadingElements = false;
      this.init();
      this.diaryDetailsComplete = JSON.parse(JSON.stringify(this.diaryDetails));
      this.loadingPage = false;
      this.selectedView = this.getPageRefreshDetails.view;
      this.currentPageLessonElements = this.getPageRefreshDetails.currentPage
    }else{
      if(this.view != undefined){
        this.selectedView = this.view;
      }
      this.initDiary(true, true);
    }
    this.SET_CONDITIONEDITOR(false);
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);

    if(this.getIsEditable && document.getElementById(this.diaryDetailsFormId) != null){
      document.getElementById(this.diaryDetailsFormId).addEventListener("input", this.changeFormHasChanged);
    }
    window.addEventListener('beforeunload', this.beforeunloadFunction);
    window.addEventListener('unload', this.unloadFunction);
  },

  beforeRouteUpdate (to, from, next) {
    this.nextRouteName = to.name;
    if(to.name === from.name && !this.copyElementsBool && (this.formChanged || this.getFormElementsChanged)){
      var self = this;
      this.showWarningModal()
      .then(ok => {
        self.closeModal();
        if(ok){
          self.formChanged = false;
          self.clearChangedDetailsLocales();
          if(to.name != "conditionEditor"){
            self.SET_FORMELEMENTSCHANGED(false);
          }
          next();
        }else{
          next(false);
        }
      });
    }else{
      next();
    }
  },

  beforeRouteLeave(to, _from, next) {
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);
    if (this.otherModalVisible || this.selectMediaVisible) {
      next(false);
    } else if (this.formChanged || (this.getFormElementsChanged && to.name != "conditionEditor")) {
      const self = this;
      this.nextRouteName = to.name;
      this.showWarningModal()
      .then(ok => {
        self.closeModal();
        if (ok) {
          if (to.params.oldDiaryId === undefined && to.name === "studyEditor") {
            to.params.oldDiaryId = self.diaryId;
          }
          if (to.name != "conditionEditor") {
            self.formChanged = false;
            self.clearChangedDetailsLocales();
            self.SET_QUESTIONLABELLIST([]);
            self.SET_FORMELEMENTSCHANGED(false);
          } else {
            const data = self.setRefreshData();
            self.SET_PAGEREFRESHDETAILS(data);
          }
          next();
        } else {
          next(false);
        }
      });
    } else {
      if (to.params.oldDiaryId === undefined && to.name === "studyEditor") {
        to.params.oldDiaryId = this.diaryId;
      }
      this.formChanged = false;
      this.clearChangedDetailsLocales();
      if (to.name != "conditionEditor") {
        this.SET_QUESTIONLABELLIST([]);
        this.SET_FORMELEMENTSCHANGED(false);
      } else {
        const data = this.setRefreshData();
        this.SET_PAGEREFRESHDETAILS(data);
      }
      next();
    }
  },

  beforeDestroy() {
    if (this.getIsEditable && document.getElementById(this.diaryDetailsFormId) != null) {
      document.getElementById(this.diaryDetailsFormId).removeEventListener("input", this.changeFormHasChanged);
    }
    window.removeEventListener("beforeunload", this.beforeunloadFunction);
    window.removeEventListener("unload", this.unloadFunction);
  },

  methods: {
    ...mapMutations([
      "SET_NOTIFICATIONTEXT",
      "SET_SELECTEDLANGUAGE",
      "SET_PAGEREFRESHBOOL",
      "SET_PAGEREFRESHDETAILS",
      "SET_QUESTIONLABELLIST",
      "SET_FORMELEMENTSCHANGED",
      "SET_ALLELEMLOCALESCHANGED",
      "SET_PREVIEWANSWERS",
      "SET_CONDITIONEDITOR",
      "SET_ALLDETAILSLOCALESCHANGED",
      "CLEAR_CHANGEDDETAILSLOCALES",
      "ADD_CHANGEDDETAILSLOCALE",
    ]),

    changeStructureLock: function(done) {
      if (done) {
        this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("interventionTranslation.structureLockModalLoading") });
        this.structureLockLoading = true;
        const lock = !this.diaryDetailsComplete.structure_lock;
        const self = this;
        this.updateQuestionnaireStructureLock(this.diaryDetails.questionnaire_id, lock).then(
          function() {
            self.diaryDetails.structure_lock = lock;
            self.diaryDetailsComplete.structure_lock = lock;
            self.structureLockLoading = false;
            self.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("interventionTranslation.structureLockModalSuccess") });
          }
        ).catch(
          function(error) {
            self.handleErrors(error, function() { self.changeStructureLock(done) }, "StructureLock");
            self.structureLockLoading = false;
          }
        );
      }
      this.structureLockModalVisible = false;
    },

    clearChangedDetailsLocales: function() {
      this.SET_ALLDETAILSLOCALESCHANGED(false);
      this.CLEAR_CHANGEDDETAILSLOCALES();
    },

    allElementsChanged: function() {
      this.SET_FORMELEMENTSCHANGED(true);
      this.SET_ALLELEMLOCALESCHANGED(true);
    },

    showWarningModal: function(){
      return new Promise((resolve, reject) => {
        this.warningChangesVisible = true;
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      })
    },

    deleteLanguageModal(done) {
      if (done) {
        const locales = this.diaryDetails.locales.filter(l => l !== this.deleteLanguage);
        this.diaryDetails.locales = locales;
        this.diaryDetailsComplete.locales = locales;
        if (!this.getAllDetailsLocalesChanged) {
          this.SET_ALLDETAILSLOCALESCHANGED(true);
        }
        this.formChanged = true;
      }
      this.deleteLanguageModalVisible = false;
    },

    initDiary: async function(created, withElements) {
      try {
        const diaryDetails = await this.requestDiaryAndLesson(this.diaryId, this.questionnaireId, withElements)
        this.initSchedules(diaryDetails);
        this.initSensorData(diaryDetails);
        this.diaryDetails = diaryDetails;
        this.loadingElements = false;
        if (created) {
          this.init();
          this.getInitialData();
        } else {
          this.setCurrentTranslations(false);
          if (this.selectedLanguage2 != "") {
            this.setCurrentTranslations(true);
          }
        }
        this.diaryDetailsComplete = JSON.parse(JSON.stringify(diaryDetails));
      } catch (error) {
        this.handleErrors(error, () => { this.initDiary(created, withElements) }, "diaryDetails");
      }
    },

    initSensorData: function(diaryDetails) {
      if (!Array.isArray(diaryDetails.sensor_data)) {
        diaryDetails.sensor_data = {
          location: false,
          pedometer: false,
        };
      } else {
        const location = diaryDetails.sensor_data.includes("location");
        const pedometer = diaryDetails.sensor_data.includes("pedometer");
        diaryDetails.sensor_data = { location, pedometer };
      }
    },

    initSchedules: function(diaryDetails) {
      if (!Array.isArray(diaryDetails.schedules)) {
        diaryDetails.schedules = [];
        return;
      }
      for (let i = 0; i < diaryDetails.schedules.length; i++) {
        const scheduleJSON = diaryDetails.schedules[i];
        const schedule = scheduleJSON.schedule;
        if (!schedule.day_of_week) {
          schedule.day_of_week = "monday";
        }
        if (!schedule.day_of_month) {
          schedule.day_of_month = 1;
        }
        if (!Array.isArray(schedule.reminders_after_minutes)) {
          schedule.reminders_after_minutes = [];
        }
        if (schedule.start || schedule.start === 0) {
          schedule.start = new Date(schedule.start).toISOString();
        }
        if (schedule.end || schedule.end === 0) {
          schedule.end = new Date(schedule.end).toISOString();
        }
        schedule.__state = {
          expire_in_seconds: !!schedule.expire_in_seconds || schedule.expire_in_seconds === 0,
          start: !!schedule.start,
          end: !!schedule.end,
        };
        schedule.id = scheduleJSON.id;
        diaryDetails.schedules[i] = schedule;
      }
    },

    getInitialData: function() {
      const self = this;
      this.requestCollaboratorsIncludingPermissions("page=0&id=" + this.getUserId, this.studyId, false, -1, -1)
        .then(function() {
          return self.requestStudyDetails(self.studyId);
        })
        .then(function(response) {
          self.studyDetails = response;
          self.loadingPage = false;
        })
        .catch(function(error) {
          self.handleErrors(error, function() { self.getInitialData() }, "");
        });
    },

    init: function(){
      this.setCurrentTranslations(false);
      if (this.diaryDetails.locales == null || this.diaryDetails.locales.length === 0) {
        this.diaryDetails.locales = ["de", "en"];
      }
      if (!this.diaryDetails.locales.includes(this.getSelectedLanguage)) {
        const language = (this.diaryDetails.locales.includes("de")) ? "de" : this.diaryDetails.locales[0];
        this.SET_SELECTEDLANGUAGE(language);
      }
    },

    showModal: function(obj){
      switch (obj.type) {
        case "StudyMedia":
          this.selectMediaVisible = obj.value;
          break;
        case "LanguageError":
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.deleteSelectedLanguageWarning')});
          break;
        case "LessonPreview":
          this.setUpdatedDiaryDetailsForPreview();
          break;
        case "any":
          this.otherModalVisible = obj.value;
          break;
        case "DeleteLanguage":
          this.deleteLanguage = obj.deleteLanguage;
          this.deleteLanguageModalVisible = true;
          break;
        default:
          break;
      }
    },

    changeFormHasChanged: function(){
      this.formChanged = true;
    },

    closeModal: function(){
      this.warningChangesVisible = false;
    },

    getDiaryDetailsErrors: function(diaryDetails, language, translation) {
      let errorText = "";
      const lengthErrors = {
        name: false,
        title: [],
        description: [],
      };
      if (!diaryDetails.name) {
        errorText += Vue.i18n.translate("generalTranslation.name");
      } else {
        lengthErrors.name = diaryDetails.name.length > 255;
      }
      if (!language.title) {
        errorText += ((errorText !== "") ? ", " : "") + Vue.i18n.translate("interventionTranslation.title");
      } else if (language.title.length > 255) {
        lengthErrors.title.push(this.getSelectedLanguage);
      }
      if (!language.description) {
        errorText += ((errorText !== "") ? ", " : "") + Vue.i18n.translate("interventionTranslation.description");
      } else if (language.description.length > 255) {
        lengthErrors.description.push(this.getSelectedLanguage);
      }
      if (translation !== "") {
        if (!translation.title) {
          errorText += ((errorText !== "") ? ", " : "") + Vue.i18n.translate("interventionTranslation.titleTranslation");
        } else if (translation.title.length > 255) {
          lengthErrors.title.push(this.selectedLanguage2);
        }
        if (!translation.description) {
          errorText += ((errorText !== "") ? ", " : "") + Vue.i18n.translate("interventionTranslation.descriptionTranslation");
        } else if (translation.description.length > 255) {
          lengthErrors.description.push(this.selectedLanguage2);
        }
      }
      const scheduleErrors = {
        expire_in_seconds: [],
        start: [],
        end: [],
        endSmallerStart: [],
      };
      for (let i = 0; i < diaryDetails.schedules.length; i++) {
        const pos = i + 1;
        const schedule = diaryDetails.schedules[i];
        if (schedule.__state.expire_in_seconds && !schedule.expire_in_seconds && schedule.expire_in_seconds !== 0) {
          scheduleErrors.expire_in_seconds.push(pos);
        }
        if (
          (schedule.__state.start && schedule.start) &&
          (schedule.__state.end && schedule.end)
        ) {
          const start = new Date(schedule.start).getTime();
          const end = new Date(schedule.end).getTime();
          if (end < start) {
            scheduleErrors.endSmallerStart.push(pos);
          }
        } else {
          if (schedule.__state.start && !schedule.start) {
            scheduleErrors.start.push(pos);
          }
          if (schedule.__state.end && !schedule.end) {
            scheduleErrors.end.push(pos);
          }
        }
      }
      if (scheduleErrors.expire_in_seconds.length > 0 || scheduleErrors.start.length > 0 || scheduleErrors.end.length > 0 || scheduleErrors.endSmallerStart > 0) {
        errorText += ((errorText !== "") ? "; " : "") + Vue.i18n.translate("schedules.schedulesErrorsText") + ": ";
        let needComma = false;
        if (scheduleErrors.expire_in_seconds.length > 0) {
          const expireInSeconds = Vue.i18n.translate("schedules.expireInSeconds");
          const position = Vue.i18n.translate("schedules.schedulesErrorsPositionText");
          const positions = scheduleErrors.expire_in_seconds.join(", ");
          errorText += `${expireInSeconds} (${position}: ${positions})`;
          needComma = true;
        }
        if (scheduleErrors.start.length > 0) {
          const comma = needComma ? ", " : "";
          const start = Vue.i18n.translate("schedules.start");
          const position = Vue.i18n.translate("schedules.schedulesErrorsPositionText");
          const positions = scheduleErrors.start.join(", ");
          errorText += `${comma}${start} (${position}: ${positions})`;
          needComma = true;
        }
        if (scheduleErrors.end.length > 0) {
          const comma = needComma ? ", " : "";
          const end = Vue.i18n.translate("schedules.end");
          const position = Vue.i18n.translate("schedules.schedulesErrorsPositionText");
          const positions = scheduleErrors.end.join(", ");
          errorText += `${comma}${end} (${position}: ${positions})`;
          needComma = true;
        }
        if (scheduleErrors.endSmallerStart.length > 0) {
          const comma = needComma ? ", " : "";
          const end = Vue.i18n.translate("schedules.end");
          const smallerThan = Vue.i18n.translate("schedules.smallerThan");
          const start = Vue.i18n.translate("schedules.start");
          const position = Vue.i18n.translate("schedules.schedulesErrorsPositionText");
          const positions = scheduleErrors.endSmallerStart.join(", ");
          errorText += `${comma}${end} ${smallerThan} ${start} (${position}: ${positions})`;
          needComma = true;
        }
      }
      if (errorText.length > 0) {
        errorText = Vue.i18n.translate("adminUsersTranslation.errorFillIn") + errorText;
      }

      for (const t in diaryDetails.translations) {
        const translation = diaryDetails.translations[t];
        const locale = translation.locale;
        if (!diaryDetails.locales.includes(locale)) continue;
        if (!lengthErrors.title.includes(locale)) {
          if (translation.title && translation.title.length > 255) {
            lengthErrors.title.push(locale);
          }
        }
        if (!lengthErrors.description.includes(locale)) {
          if (translation.description && translation.description.length > 255) {
            lengthErrors.description.push(locale);
          }
        }
      }
      let needsPrefix = errorText.length > 0;
      if (lengthErrors.name) {
        errorText += (needsPrefix ? "; " : "") + Vue.i18n.translate("interventionTranslation.nameTooLong");
        needsPrefix = true;
      }
      if (lengthErrors.title.length > 0) {
        lengthErrors.title.sort();
        const locales = " (" + lengthErrors.title.join(", ") + ")";
        errorText += (needsPrefix ? "; " : "") + Vue.i18n.translate("interventionTranslation.titleTooLong") + locales;
        needsPrefix = true;
      }
      if (lengthErrors.description.length > 0) {
        lengthErrors.description.sort();
        const locales = " (" + lengthErrors.description.join(", ") + ")";
        errorText += (needsPrefix ? "; " : "") + Vue.i18n.translate("interventionTranslation.descriptionTooLong") + locales;
        needsPrefix = true;
      }
      return errorText;
    },

    saveDiaryDetails: function() {
      const errorText = this.getDiaryDetailsErrors(
        this.diaryDetails,
        this.currentTranslation,
        this.currentTranslation2,
      );
      if (errorText.length > 0) {
        this.SET_NOTIFICATIONTEXT({ type: "error", text: errorText });
      } else {
        this.isSaved = true;
        this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("diaryTranslation.updateDiaryDetailsLoad") });

        const details = JSON.parse(JSON.stringify(this.diaryDetails));
        if (!details.page_color) {
          details.page_color = this.$defaultLessonPageColor;
        }
        //all translations in translations array
        if (this.currentTranslation !== "") {
          this.addTranslation(details.translations, this.currentTranslation);
        }
        if (this.currentTranslation2 !== "") {
          this.addTranslation(details.translations, this.currentTranslation2);
        }
        details.translations = details.translations.filter(t => this.changedLocales.includes(t.locale));
        this.cleanupSchedules(details);
        this.transformSensorData(details);
        const json = {
          data: {
            type: "diary",
            attributes: details,
          },
        };
        this.updateDiaryDetailsRequest(json, this.diaryId)
          .then(() => {
            this.updateLessonDetailsRequest({ locales: details.locales }, details.questionnaire_id);
          })
          .then(() => {
            this.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("diaryTranslation.updateDiaryDetailsSuccess") });
          })
          .catch((error) => {
            if (error.response?.status === 422 && error.response.data?.errors) {
              const regex = new RegExp(/data\.attributes\.schedules\.[0-9]+\.id/, "g");
              const errorMessages = JSON.stringify(error.response.data.errors);
              const matches = errorMessages.match(regex);
              if (matches != null) {
                this.handleAlreadyDeletedSchedules(matches);
                return;
              }
            }
            this.handleErrors(error, () => this.saveDiaryDetails(), "");
          });
      }
    },

    handleAlreadyDeletedSchedules: function(matches) {
      const invalidIndeces = [];
      const start = 26; // Length of "data.attributes.schedules."
      for (const key of matches) {
        const end = key.length - 3; // Length of ".id"
        const index = parseInt(key.slice(start, end), 10);
        if (!Number.isNaN(index) && !invalidIndeces.includes(index)) {
          invalidIndeces.push(index);
        }
      }
      if (invalidIndeces.length !== 0) {
        invalidIndeces.sort((a, b) => a < b ? 1 : -1);
        for (const index of invalidIndeces) {
          this.diaryDetails.schedules.splice(index, 1);
        }
        this.SET_NOTIFICATIONTEXT({
          type: "error",
          text: Vue.i18n.translate("schedules.schedulesAlreadyDeleted", { length: invalidIndeces.length }),
        });
      } else {
        this.requestDiary(this.diaryId, this.questionnaireId).then((diaryDetails) => {
          this.initSchedules(diaryDetails);
          this.diaryDetails.schedules = diaryDetails.schedules;
          this.SET_NOTIFICATIONTEXT({ type: "error", text: Vue.i18n.translate("schedules.schedulesUnexpectedError") });
        });
      }
    },

    transformSensorData: function(diaryDetails) {
      const sensorData = [];
      if (diaryDetails.sensor_data.location) {
        sensorData.push("location");
      }
      if (diaryDetails.sensor_data.pedometer) {
        sensorData.push("pedometer");
      }
      diaryDetails.sensor_data = sensorData;
    },

    cleanupSchedules: function(diaryDetails) {
      for (const schedule of diaryDetails.schedules) {
        switch (schedule.type) {
          case "weekly":
            schedule.day_of_month = undefined;
            break;
          case "monthly":
            schedule.day_of_week = undefined;
            break;
          default:
            schedule.day_of_week = undefined;
            schedule.day_of_month = undefined;
        }
        const state = schedule.__state;
        if (!state.expire_in_seconds || (!schedule.expire_in_seconds && schedule.expire_in_seconds !== 0)) {
          schedule.expire_in_seconds = undefined;
        }
        if (!state.start || !schedule.start) {
          schedule.start = undefined;
        } else {
          schedule.start = new Date(schedule.start).getTime();
        }
        if (!state.end || !schedule.end) {
          schedule.end = undefined;
        } else {
          schedule.end = new Date(schedule.end).getTime();
        }
        delete schedule.__state;
      }
    },

    changeLanguage2: function(language){
      this.selectedLanguage2 = language;
    },

    changeTranslationMode: function(isTranslationMode){
      this.isTranslationMode = isTranslationMode;
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },

    unloadFunction: function(){
      this.SET_PAGEREFRESHBOOL(true);
      this.closeNotification();
      this.isTranslationMode = false;
      this.currentPageLessonElements = 1;
      var data = this.setRefreshData();
      this.SET_PAGEREFRESHDETAILS(data);
    },

    setRefreshData: function(){
      //all translations in translations array
      const diaryDetailsArr = JSON.parse(JSON.stringify(this.diaryDetails));
      if (this.currentTranslation != "") {
        this.addTranslation(diaryDetailsArr.translations, this.currentTranslation);
        this.currentTranslation = "";
      }
      if (this.currentTranslation2 != "") {
        this.addTranslation(diaryDetailsArr.translations, this.currentTranslation2);
        this.currentTranslation2 = "";
      }
      const data = {
        id: this.diaryId,
        diaryDetails: diaryDetailsArr,
        studyDetails: this.studyDetails,
        view: this.selectedView,
        formChanged: this.formChanged,
        currentPage: this.currentPageLessonElements,
      };
      return data;
    },

    setUpdatedDiaryDetailsForPreview: function(){
      this.closeNotification();
      const diaryDetailsArr = JSON.parse(JSON.stringify(this.diaryDetails));
      if (this.currentTranslation) {
        const translation = JSON.parse(JSON.stringify(this.currentTranslation));
        this.addTranslation(diaryDetailsArr.translations, translation);
      }
      if (this.currentTranslation2) {
        const translation = JSON.parse(JSON.stringify(this.currentTranslation2));
        this.addTranslation(diaryDetailsArr.translations, translation);
      }
      this.diaryDetailsComplete = diaryDetailsArr;
    },

    showView: function(view){
      this.selectedView = view;
    },

    routeTo: function(obj){
      if(obj.name === 'conditionEditor'){
        this.currentPageLessonElements = obj.params.page;
        delete obj.params['page'];
        obj.params.lessonId = this.diaryDetails.questionnaire_id;
      }else if(obj.name === 'diaryEditor'){
        //route changes -> get new diary details, studyDetails
        //permissions and elements already set
        //in route.params.diaryId watch function
        this.copyElementsBool = true;
      }

      router.push(obj);
    },

    exportDiary: function(){
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('diaryTranslation.exportDiaryLoad')});
      var self = this;
      this.exportDiaryRequest(this.diaryId)
      .then( function(response){
        let dataStr = JSON.stringify(response, null, "\t");
        let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
        let exportFileDefaultName = 'data.json';
        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('diaryTranslation.exportDiarySuccess')});
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.exportDiary() }, "");
      });
    },

    beginCreateCopy: function(){
      this.formChanged = false;
    },

    changeAttributeForDetails: function(obj) {
      this.closeNotification();
      if (obj.name === "title" || obj.name === "description") {
        if (!obj.isTranslation) {
          this.currentTranslation[obj.name] = obj.value;
        } else {
          this.currentTranslation2[obj.name] = obj.value;
        }
        const locale = !obj.isTranslation ? this.getSelectedLanguage : this.selectedLanguage2;
        this.ADD_CHANGEDDETAILSLOCALE(locale);
        this.formChanged = true;
      } else {
        if (this.allLocalesAttributeChanged(obj)) {
          if (!this.getAllDetailsLocalesChanged) {
            this.SET_ALLDETAILSLOCALESCHANGED(true);
          }
          this.formChanged = true;
        }
      }
      return true;
    },

    allLocalesAttributeChanged(obj) {
      switch (obj.name) {
        case "AddLanguage": {
          const language = obj.value;
          this.addLanguage(language);
          this.ADD_CHANGEDDETAILSLOCALE(language);
          this.SET_SELECTEDLANGUAGE(language);
          break;
        }
        default: {
          this.diaryDetails[obj.name] = obj.value;
          this.diaryDetailsComplete[obj.name] = obj.value;
        }
      }
      return true;
    },

    changeSchedulesForDetails: function(obj) {
      const schedules = this.diaryDetails.schedules;
      switch (obj.name) {
        case "start": {
          schedules[obj.index].start = obj.selectedDate;
          break;
        }
        case "end": {
          schedules[obj.index].end = obj.selectedDate;
          break;
        }
        case "timeOfDay": {
          schedules[obj.index].time_of_day = obj.timeOfDay;
          break;
        }
        case "reminders": {
          const schedule = schedules[obj.index];
          const currentReminders = JSON.stringify(schedule.reminders_after_minutes);
          if (currentReminders !== JSON.stringify(obj.reminders)) {
            schedule.reminders_after_minutes = obj.reminders;
            if (!this.getAllDetailsLocalesChanged) {
              this.SET_ALLDETAILSLOCALESCHANGED(true);
            }
            this.formChanged = true;
          }
          break;
        }
        case "add": {
          this.SET_NOTIFICATIONTEXT({ type: "load", text: Vue.i18n.translate("schedules.addingSchedule") });
          const newSchedules = [];
          for (let i = 0; i < obj.amount; i++) {
            newSchedules.push({
              type: "daily",
              time_of_day: { hour: 12, minute: 0 },
              reminders_after_minutes: [],
            });
          }
          const self = this;
          this.createDiarySchedules(newSchedules, this.diaryId).then(function(response) {
            const diaryDetails = response.data.data.attributes;
            self.initSchedules(diaryDetails);
            schedules.push(...diaryDetails.schedules);
            self.SET_NOTIFICATIONTEXT({ type: "success", text: Vue.i18n.translate("schedules.addedSchedule") });
          }).catch(function(error) {
            self.handleErrors(error, function() { self.changeSchedulesForDetails(obj) }, "");
          });
          break;
        }
        case "delete": {
          const schedule = schedules[obj.index];
          if (schedule !== undefined) {
            const self = this;
            this.deleteDiarySchedules([schedule.id], this.diaryId).then(function() {
              schedules.splice(obj.index, 1);
            }).catch(function(error) {
              self.handleErrors(error, function() { self.changeSchedulesForDetails(obj) }, "");
            });
          }
          break;
        }
        default:
      }
    },

    addLanguage(language) {
      const locales = JSON.parse(JSON.stringify(this.diaryDetails.locales));
      locales.push(language);
      locales.sort();
      this.diaryDetails.locales = locales;
      this.diaryDetailsComplete.locales = locales;
    },

    setCurrentTranslations: function(isTranslation){
      this.loadingLanguage = true;
      const language = !isTranslation ? this.getSelectedLanguage : this.selectedLanguage2;

      if (this.selectedLanguage2 === "" && this.currentTranslation2 != "") {
        this.addTranslation(this.diaryDetails.translations, this.currentTranslation2);
        this.currentTranslation2 = "";
      }

      let translation = "";
      const index = this.diaryDetails.translations.findIndex(elem => elem.locale === language);
      if (index != -1) {
        translation = this.diaryDetails.translations[index];
        this.diaryDetails.translations.splice(index, 1);
      } else if (language != "") {
        translation = {
          locale: language,
          title: "",
          description: "",
        };
      }

      if (!isTranslation) {
        if (this.currentTranslation != "") {
          this.addTranslation(this.diaryDetails.translations, this.currentTranslation);
        }
        if (translation != "") {
          this.currentTranslation = translation;
        }
      } else {
        if (this.currentTranslation2 != "") {
          this.addTranslation(this.diaryDetails.translations, this.currentTranslation2);
        }
        if (translation != "" && this.selectedLanguage2 != "") {
          this.currentTranslation2 = translation;
        }
      }
      this.loadingLanguage = false;
    },

    beforeunloadFunction: function(e){
      if(this.formChanged || this.getFormElementsChanged){
        e.preventDefault();
        e.returnValue = '';
        return;
      }
      delete e['returnValue'];
    },
  }
}
</script>
